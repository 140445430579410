/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/globals.css"

export const onClientEntry = () => {
  const script = document.createElement("script")
  script.src = "https://tally.so/widgets/embed.js"
  script.async = true
  document.body.appendChild(script)
}
