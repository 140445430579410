exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ikigai-js": () => import("./../../../src/pages/ikigai.js" /* webpackChunkName: "component---src-pages-ikigai-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instrukcja-js": () => import("./../../../src/pages/instrukcja.js" /* webpackChunkName: "component---src-pages-instrukcja-js" */),
  "component---src-pages-invite-js": () => import("./../../../src/pages/invite.js" /* webpackChunkName: "component---src-pages-invite-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-onas-js": () => import("./../../../src/pages/onas.js" /* webpackChunkName: "component---src-pages-onas-js" */),
  "component---src-pages-panel-js": () => import("./../../../src/pages/panel.js" /* webpackChunkName: "component---src-pages-panel-js" */),
  "component---src-pages-prywatnosc-js": () => import("./../../../src/pages/prywatnosc.js" /* webpackChunkName: "component---src-pages-prywatnosc-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-skills-js": () => import("./../../../src/pages/skills.js" /* webpackChunkName: "component---src-pages-skills-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-whatsapp-js": () => import("./../../../src/pages/whatsapp.js" /* webpackChunkName: "component---src-pages-whatsapp-js" */)
}

